export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'home',
        label: 'Home',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/dashboard/ecommerce',
    },
    {
        key: 'chart',
        label: 'Chart',
        isTitle: false,
        icon: 'uil-chart',
        url: '/chart',
    },
    {
        key: 'strategies',
        label: 'Strategies',
        isTitle: false,
        icon: 'uil-bolt-alt',
        url: '/strategy/all',
    },
    {
        key: 'datavalidation',
        label: 'Data Validation',
        isTitle: false,
        icon: 'uil-shield-check',
        url: '/datavalidation',
    },
];

export { MENU_ITEMS };
