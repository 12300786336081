import Routes from 'routes/Routes';
import { configureFakeBackend } from 'helpers';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
import config from 'config';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

const App = () => {
    configureFakeBackend();
    console.log(config);
    return <Routes />;
};

export default App;
